import { useTranslation } from "react-i18next"
import useQueryStringValidator from "../hooks/useQueryStringValidator"
import PaymentMethods from "../components/payment/paymentMethods/PaymentMethods"
import ResumenCompraDsk from "../components/payment/resumen_compra/ResumenCompraDsk"
import ResumenCompraMob from "../components/payment/resumen_compra/ResumenCompraMob"
import useHandleBounds from "../hooks/useHandleBounds"
import { useState } from "react"

const Payment = () => {
  useQueryStringValidator()
  const { t } = useTranslation()
  const { currency } = useHandleBounds()
  const [openBottom, setOpenBottom] = useState(false)

  return (
    <div className="w-full flex flex-wrap justify-center">
      <div className="container grow flex justify-center">
        <div className="flex flex-col p-2 lg:px-10 md:pb-10 md:pt-0">
          <div className="md:mt-11 md:mb-6">
            <h2 className="flex font-avianca-bold text-lg md:text-[32px] leading-10">
              {t("translation:Payment.title")}
            </h2>
          </div>
          <div className="w-full flex flex-wrap justify-center paymentDetail:justify-start mb-20 lg:mb-0">
            <div className="max-w-[699px] grow bg-white shadow">
              <PaymentMethods currency={currency} />
            </div>
            <div className="hidden max-w-[365px] xl:w-[365px] lg:block mt-3 paymentDetail:mt-0 paymentDetail:ml-8">
              <ResumenCompraDsk currency={currency} />
            </div>
          </div>
        </div>
      </div>
      {openBottom && (
        <div className="fixed inset-0 w-full h-full pointer-events-auto z-50 bg-black opacity-60"></div>
      )}
      <div className={`z-50 block lg:hidden w-full fixed bottom-0`}>
        <ResumenCompraMob
          currency={currency}
          openBottom={openBottom}
          setOpenBottom={setOpenBottom}
        />
      </div>
    </div>
  )
}

export default Payment
